<template>
  <div class="login-container">
    <div class="card">
      <div class="logo">
        <img class="logo_img" src="@/assets/logo.png" />
      </div>
      <div class="title">心理和疼痛量表评估软件</div>
      <div class="form">
        <el-form
          ref="loginFormRef"
          class="demo-loginForm"
          :model="form"
          :rules="rules"
          status-icon
        >
          <el-form-item prop="username">
            <el-input
              v-model.trim="form.username"
              placeholder="用户名"
              prefix-icon="el-icon-user"
              size="large"
              @blur="input_blur('username')"
              @focus="input_foucs('username')"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model.trim="form.password"
              placeholder="密码"
              prefix-icon="el-icon-lock"
              size="large"
              :type="passwordType"
              @keyup.enter="save"
              @blur="input_blur('password')"
              @focus="input_foucs('password')"
            >
              <i
                v-if="passwordType == 'password'"
                class="iconfont el-icon-yincangmima"
                slot="suffix"
                @click="handlePassword"
              ></i>
              <i
                v-else
                class="el-icon-view"
                slot="suffix"
                @click="handlePassword"
              ></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div v-loading="loading" class="btn" @click="save">登录</div>
          </el-form-item>
        </el-form>
      </div>
      <div class="version">V 1.0.0.1</div>
    </div>
  </div>
</template>

<script>
import { vuplexMessage } from "@/utils/vuplex";
import { encrypt } from "@/utils/crypto";

export default {
  name: "Index",
  data() {
    return {
      loginFormRef: null,
      form: {
        username: "",
        password: "",
      },
      passwordType: "password",
      rules: {
        username: [
          { required: true, trigger: "blur", message: "请输入用户名" },
          {
            min: 4,
            max: 20,
            message: "用户名必须在4-20位之间",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      title: "",
      dialogFormVisible: false,
    };
  },
  mounted() {},
  methods: {
    async save() {
      let that = this;
      if (this.form.password == "") {
        this.$message.error("密码不能为空");
        return;
      }
      if (this.form.password.length < 6) {
        this.$message.error("密码至少需要6位");
        return;
      }
      this.$refs["loginFormRef"].validate(async (valid) => {
        if (valid) {
          try {
            that.loading = true;
            await that.$store.dispatch("login", {
              username: that.form.username,
              password: encrypt(that.form.password),
            });
            await that.$router.push({
              path: "choose_patient",
            });
          } finally {
            that.loading = false;
          }
        }
      });
    },
    handlePassword() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    input_blur(e) {
      let content = "";
      if (e == "username") {
        content = this.form.username;
      } else if (e == "password") {
        content = this.form.password;
      }
      vuplexMessage("Keyboard", "EndInput", content);
    },
    input_foucs(e) {
      let content = "";
      if (e == "username") {
        content = this.form.username;
      } else if (e == "password") {
        content = this.form.password;
      }
      vuplexMessage("Keyboard", "StartInput", content);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1024px;
  height: 1440px;
  background-image: url(../../assets/login_bg.jpg);
}
.logo_img {
  width: 218px;
  height: 149px;
  margin-top: 60px;
}
.title {
  margin-top: 32px;
  font-size: 50px;
  font-weight: bold;
  line-height: 127px;
  color: #078599;
}
.card {
  position: relative;
  width: 689px;
  height: 926px;
  margin: 0px auto;
  text-align: center;
  background: #ffffff;
}
.form {
  width: 438px;
  margin: 0px auto;
  margin-top: 40px;
}
.version {
  position: absolute;
  bottom: 28px;
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  color: #c8cbd2;
  text-align: center;
}
.btn {
  width: 282px;
  height: 78px;
  margin: 0px auto;
  margin-top: 90px;
  font-size: 36px;
  font-weight: 400;
  line-height: 78px;
  color: #ffffff;
  cursor: pointer;
  background: #078599;
  border-radius: 5px;
}
</style>
