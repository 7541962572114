import CryptoJS from "crypto-js";

let key = "aaaabbbbccccddddeeeeffffgggghhhh";
let iv = "1234567812345678";

key = CryptoJS.enc.Utf8.parse(key);
let param = {
  iv: CryptoJS.enc.Utf8.parse(iv),
  mode: CryptoJS.mode.CBC,
  padding: CryptoJS.pad.Pkcs7,
};

/**
 * 加密
 * @param {*} text 需要加密的内容
 * @returns 加密后内容
 */
export function encrypt(text) {
  return CryptoJS.AES.encrypt(text, key, param).toString();
}

/**
 * 解密
 * @param {*} text 需要解密的内容
 * @returns 解密后内容
 */
export function decrypt(text) {
  var result = CryptoJS.AES.decrypt(text, key, param);
  return result.toString(CryptoJS.enc.Utf8);
}
